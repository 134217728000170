import styled from "@emotion/styled";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import { Text, Title } from "../../../genericComponents";

const TextColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  width: 40%;
  height: 100%;
  object-fit: cover;
  backdrop-filter: blur(12px);
  backdrop-filter: brightness(15%);
  fill-opacity: 0;
  padding: 20px;
  text-align: left;
`;
const LayerRow = styled.div<{ img: string }>`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap:"10px";
  width: 100%;
  height: 414px;

  background: ${(props) => props.img && `url(${props.img})`};
  background-size: cover;
`;
const ButtonRoute = styled.p`
  border: 1px solid rgba(255, 255, 255, 1);
  text-align: center;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  padding: 5px 0;
  margin-top: 40px;
`;
type ICarouselItem = {
  src: string;
  title: string;
  subtitle: string;
  offer: string;
  price: string;
  place: string;
  route: string;
  btnText: string;
  id: string;
};

const CarouselItem = ({ src, subtitle, title, offer, price, place, route, btnText, id }: ICarouselItem) => {
  const navigation = useNavigate();
  return (
    <LayerRow img={src}>
      <TextColumn>
        <Text size="11px" color="#fff">
          {place}
        </Text>
        <Title style={{wordBreak:"break-word",lineHeight:1.5}} top="10px" size="14px" weight="bolder" color="#fff">
          {title?.toUpperCase()}
        </Title>
        <Text style={{ overflow: "hidden", textOverflow: "ellipsis", display: " -webkit-box", lineHeight: "16px", maxHeight: "32px" }} top="10px" size="12px" color="#fff">
          {subtitle}
        </Text>

        <Title top="0px" size="17px" weight="300" color="#fff">
          ...
        </Title>
        <Title top="10px" size="17px" weight="300" color="#fff">
          {offer}
        </Title>
        <Title size="17px" weight="300" color="#fff">
          {price}
        </Title>
        <ButtonRoute
          onClick={() =>
            navigation({
              pathname: route,
              search: createSearchParams({
                eventId: id,
              }).toString(),
            })
          }
        >
          {btnText}
        </ButtonRoute>
      </TextColumn>
    </LayerRow>
  );
};

export default CarouselItem;
