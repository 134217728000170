import styled from "@emotion/styled";
import { IEvents, IEventsBmc } from "../../context/EventsContext";
import { Text } from "../../genericComponents";
import moment from "moment";
import CalendarIcon from "../../icons/services/calendarIcon";
import CircleIcon from "../CircleIcon";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from "react-scroll";

const Card = styled.div`
  position: relative;
  min-height: 335px;
  width: 80%;
  margin: 0 auto;
  background-color: #ffffff;
  margin-top: 20px;
`;
const EventImage = styled.div<{ image: string }>`
  background: ${(props) => props.image && `url(${props.image})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 142px;
`;
const ColumnTime = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 85%;
`;
const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  padding: 10px 15px;
`;
const RowTime = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  svg {
    width: 10%;
    margin-right: 20px;
  }
`;
export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  position: absolute;
  top: 20px;
  right: 20px;
`;
export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 46px;
  justify-content: center;
  background-color: #5b656e;
  width: 100%;
`;
export const ContainerLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 46px;
  justify-content: center;
  background-color: #fff;
  width: 100%;
`;
export const Logo = styled.img`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35px;
  justify-content: center;
  width: auto;
`;

const EventCardBusiness = ({ eventData }: { eventData: IEvents }) => {
  return (
    <Element name={eventData?.id}>
      <Card>
        {eventData?.image && <EventImage image={eventData?.image} />}
        {/* {eventData?.businessData?.logo && (
          <ContainerLogo>
            <Logo src={eventData?.businessData?.logo} />
          </ContainerLogo>
        )} */}
        <ContainerTitle>
          <Text color="#fff" align="left" size="14px" weight="600">
            {eventData?.title}
          </Text>
        </ContainerTitle>
        <InfoContainer>
          <Text size="13px" weight="200">
            {`Empresa organizadora: ${eventData?.businessData?.name}`}
          </Text>
          <RowTime>
            <CalendarIcon />
            <ColumnTime>
              <Text size="13px" weight="200">
                Fecha: {moment(eventData?.initTimestamp).format("DD/MM/YYYY")}–{moment(eventData?.finishTimestamp).format("DD/MM/YYYY")}
              </Text>
              <Text size="13px" weight="200">
                Hora: {eventData?.allDay ? "Todo el día" : eventData?.hour}
              </Text>
            </ColumnTime>
          </RowTime>
          <Text top="20px" size="13px" weight="200">
            {eventData?.description}
          </Text>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            {eventData?.local && (
              <Text top="20px" size="13px" weight="200">
                Local: {eventData?.local}
              </Text>
            )}
            {eventData?.phone && (
              <Text top="20px" size="13px" weight="200">
                Teléfono: {eventData?.phone}
              </Text>
            )}
          </div>
        </InfoContainer>
      </Card>
    </Element>
  );
};

export default EventCardBusiness;
