import styled from "@emotion/styled";
import React, { useContext, useEffect, useState } from "react";
import InputWithLegend from "../../../../components/InputWithLegend";
import Layout from "../../../../components/Layout";
import SectionHeader from "../../../../components/SectionHeader";
import CalendarIcon from "../../../../icons/services/calendarIcon";
import InputCheckbox from "../../../../components/InputCheckbox";

import TextAreaWithLegend from "../../../../components/TextAreaWithLegend";
import InputButtonWithProps from "../../../../components/InputButton";
import SectionFooter from "../../../../components/SectionFooter";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { addDoc, collection } from "firebase/firestore";
import ModalCusstom from "../../../../components/ModalCustom";
import { useNavigate } from "react-router";
import { Text } from "../../../../genericComponents";
import DatePickerCustom from "../../../../components/DatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Container = styled.div`
  background-color: #f6f6f6;
  padding-bottom: 80px;
`;

const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerContainer = styled.form`
  margin: 20px auto;
  width: 80%;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
`;
const InputInvisible = styled.input`
  display: flex;
`;
const Invisible = styled.div`
  display: none;
`;
export const ImageCircle = styled.div<{ imagePreview: string }>`
  height: 100px;
  width: 100%;
  background: ${(props) => (props.imagePreview ? `url(${props.imagePreview})` : "#F6F6F6")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid black;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
`;

const Image = styled.img`
  height: 27px;
  width: 27px;
`;
const ContainerDiv = styled.div`
  padding: 15px;
`;
const NewEvent = () => {
  const { db, user, uploadImage, addNotiEventBmc } = useContext(FirebaseContext);
  const navigation = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState("");

  const [newEvent, setNewEvent] = useState({
    allDay: false,
    isOffer: false,
    hour: "",
    title: "",
    description: "",
    phone:"",
    local:"",
    externalUrl: "",
    initTimestamp: 0,
    finishTimestamp: 0,
    image: null,
  });

  const [disableButton, setDisableButton] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    switch (e.target.name) {
      case "initTimestamp":
        const init = new Date(e.target.value).getTime();
        setNewEvent({ ...newEvent, [e.target.name]: init });
        break;
      case "image":
        setImage(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setPreview(url);
        break;
      case "finishTimestamp":
        const finish = new Date(e.target.value).getTime();
        setNewEvent({ ...newEvent, [e.target.name]: finish });
        break;

      default:
        setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
        break;
    }
  };

  const clickCheckbox = (e) => {
    setNewEvent({
      ...newEvent,
      allDay: !newEvent.allDay,
      hour: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setOpenModal(true);
      setModalMessage("Creando evento...");

      // if (image) {
      const url = await uploadImage(image as File);
      const res = await addDoc(collection(db, "events-business"), {
        ...newEvent,
        image: url,
        businessId: user.businessId,
        developer: user.id === "zWlkqbzS8w6Jvb745K1e",
      });

      const docToAdd = { ...newEvent, image: url, businessId: user.businessId, id: res.id, developer: user.id === "zWlkqbzS8w6Jvb745K1e" };
      // } else {
      //   const res = await addDoc(collection(db, "events-business"), {
      //     ...newEvent,
      //     businessId: user.businessId,
      //     developer: user.id === "zWlkqbzS8w6Jvb745K1e",
      //   });

      //   docToAdd = { ...newEvent, businessId: user.businessId, id: res.id, developer: user.id === "zWlkqbzS8w6Jvb745K1e" };
      // }
      if (url) {
        await addNotiEventBmc(docToAdd, "events-business", user.id === "zWlkqbzS8w6Jvb745K1e");
      } else alert("Ha habido un error subiendo la imagen, por favor prueba de nuevo más tarde");

      setOpenModal(true);
      setModalMessage("Evento creado correctamente.");
    } catch (error) {
      setOpenModal(true);
      setModalMessage("Ha habido un error, inténtelo de nuevo más tarde.");
    } finally {
      setTimeout(() => {
        setOpenModal(false);
        setModalMessage("");
        navigation("/my-events");
      }, 3000);
    }
  };

  useEffect(() => {
    if (((newEvent.hour || newEvent.allDay) && newEvent.title && newEvent.description && newEvent.initTimestamp && newEvent.finishTimestamp) || newEvent.image) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [newEvent]);

  return (
    <Layout>
      <Container>
        <SectionHeader title="PUBLICAR EVENTO" showBackButton={true} />
        <InnerContainer onSubmit={handleSubmit}>
          <DatePickerCustom preIcon={<CalendarIcon />} label="initTimestamp" data={newEvent} legendColor="#5F5F5F" setFunct={setNewEvent} title="Fecha inicio" width="100%" />
          <DatePickerCustom
            preIcon={<CalendarIcon />}
            label="finishTimestamp"
            data={newEvent}
            legendColor="#5F5F5F"
            setFunct={setNewEvent}
            title="Fecha fin"
            width="100%"
            initSelected={newEvent.initTimestamp}
          />
          <InputCheckbox text="Todo el día" id="allDay" name="allDay" onChange={clickCheckbox} checked={newEvent.allDay} />
          <InputWithLegend preIcon={<CalendarIcon />} title="Hora*" name="hour" type="time" disabled={newEvent.allDay} value={newEvent.hour} onChange={handleChange} />

          <InputWithLegend title="Título del evento*" name="title" type="text" value={newEvent.title} onChange={handleChange} />

          <TextAreaWithLegend title="Descripción del evento*" legendColor="#5F5F5F" legendSubtitleColor="#5F5F5F" name="description" value={newEvent.description} onChange={handleChange} />
          <InputWithLegend title="Local" name="local" type="text" value={newEvent?.local} onChange={handleChange} />
          <InputWithLegend title="Teléfono" name="phone" type="text" value={newEvent?.phone} onChange={handleChange} />
          <InputWithLegend title="Enlace web" name="externalUrl" type="text" value={newEvent?.externalUrl} onChange={handleChange} />
          <ContainerDiv>
            <Text left="10px" color="#5F5F5F" size="14px" top="10px" bottom="10px">
              Imagen del evento
            </Text>
            <ImageCircle
              imagePreview={preview ? preview : "https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/default_img.jpeg?alt=media&token=860e4b3e-fe68-4359-9e90-1e7a79fc7a1d"}
              onClick={() => document.getElementById("image").click()}
            >
              {!image && !preview && <Image src="/upload.png" />}
            </ImageCircle>
            <Text size="11px" width="80%" left="auto" right="auto" top="20px" weight="200" align="center">
              Por favor, sube una imagen en formato JPG y de forma rectangular para garantizar la mejor visualización.
            </Text>
          </ContainerDiv>
          <Invisible>
            <InputInvisible required name="image" type="file" id="image" onChange={handleChange} title="Imagen del evento" />
          </Invisible>
          <FormControlLabel
            style={{ marginLeft: "10px" }}
            control={<Checkbox onChange={() => setNewEvent({ ...newEvent, isOffer: !newEvent?.isOffer })} checked={newEvent?.isOffer} />}
            label="Es una oferta"
          />
          <ContainerButton>
            <InputButtonWithProps disabled={disableButton} type="submit" border="none" text="Publicar evento" />
          </ContainerButton>
        </InnerContainer>
        <SectionFooter src="https://firebasestorage.googleapis.com/v0/b/bmc-app-87a78.appspot.com/o/bmc%2Fnetwork%2Fpie_network.jpg?alt=media&token=536a2fff-50a1-4ddf-8dc5-fdeca59294f5" />
      </Container>
      <ModalCusstom open={openModal} message={modalMessage} />
    </Layout>
  );
};

export default NewEvent;
